import React from "react";
import Main from "../../../screens/main/MainScreen";
import {useSelector} from "react-redux";

const HeaderFunction = () => {
    const count = useSelector(state => state.counter.count);

    return (
                    <div className={`forms__top`}>
                        <a className={`text-medium forms__top-text`}
                           target="_blank" rel="noreferrer"
                        >{count} DINKS</a>
                    </div>
                )
}
export default HeaderFunction;
