export const  PBcolors = ['pickleball-white', 'pickleball-blue', 'pickleball-yellow', 'pickleball-green', 'pickleball-pink'];
export const  PBColorsLowScore = ['pickleball-white', 'pickleball-green'];
export const mediumDurationDesktop = 4100;
export const mediumDurationMobile = 3600;
export const minPBHeight = 4;
export const maxPBHeight = 6;

export class PickleballClass {
   duration: number;
   color: string;
   height: number;
   // y: string;
   left: number;
   speedMedium: number;
   url: string;
   dropId: string;
   points: number;
   ballIncreasing: number;

    constructor(innerWidth: number, innerHeight: number, value: number, id: string, isMobile: boolean ) {
        // this.speedMedium = (mediumDuration / 360) * innerWidth;
        // this.speedMedium = (mediumDuration / 360) * innerHeight;
        this.speedMedium = ((isMobile ? mediumDurationMobile : mediumDurationDesktop) / 740) * innerHeight;
        this.duration = this.getDuration();
        // this.color = color ? color : this.getRandomElement(PBcolors);
        this.color = this.getColorBallInfo(value);
        this.height = this.randomIntFromInterval(minPBHeight, maxPBHeight);
        this.left = this.randomIntFromInterval(100, innerWidth - 100);
        // this.y = `${this.randomIntFromInterval((innerHeight/2), 50)}px`;
        this.url = this.getUrlByValue(value);
        this.points =  value;
        this.dropId = id;
        if (this.url) {
            this.color = '';
        }
        console.log('innerWidth', innerWidth);
        const increaseWidthSpeed = isMobile ? [(innerWidth/7), (innerWidth/3.5)] : [innerWidth/8.5, innerWidth/5.5];
        this.ballIncreasing = this.getRandomInRange(increaseWidthSpeed[0], increaseWidthSpeed[1]) ;
    }

    private randomIntFromInterval(min: number, max: number) { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    private getRandomElement(array: any[]) {
        return array[Math.floor(Math.random() * array.length)];
    }
    private getDuration() {
        const minDuration = this.speedMedium - 0.40*this.speedMedium;
        return this.randomIntFromInterval(minDuration, this.speedMedium);
        // return Math.floor(Math.random() * (4000 - 3700)) + 4000;
    }
    private getRandomInRange(min: number, max: number) {
        return Math.random() * (max - min) + min;
    }
    public pointsByBallColor() {
        switch (this.color) {
            case 'pickleball-pink':
                return {points: 2 * 3, time: false, lives: false};
            case 'pickleball-blue':
                return {points: 3 * 3, time: false, lives: false};
            case 'pickleball-green':
                return {points: 3, time: true, lives: false};
            case 'pickleball-yellow':
                return {points: 3, time: false, lives: true};
            case 'pickleball-white':
                return {points: 3, time: false, lives: false};
            default:
                return {points: 3, time: false, lives: false};
        }
    }
    public getColorBallInfo(value: number) {
        switch (value) {
            case 6:
                return 'pickleball-pink';
            case 9:
                return '';
            case 3:
                return this.getRandomElement(PBColorsLowScore);
            default:
                return '';
        }
    }
    public getUrlByValue(value: number) {
        switch (value) {
            case 6:
                return '';
            case 9:
                return '/prizeball-blue.png';
            case 3:
                return '';
            default:
                return '/prizeball-orange.png';
        }
    }

    public getRewardByPoints(points: number, collectibles: any) {
       if (points > 2000) {
           return
       }
    }
}

export interface PickleballI {
    duration: number;
    color: string;
    height: number;
    // y: string;
    left: number;
    speedMedium: number;
    url: string;
    dropId: string;
    points: number;
    ballIncreasing: number;
}

export interface DropI {
    "status": true,
    "drop": {
        "id": string,
        "bot_id": string,
        "person_id": string,
        "reward": {
            "type": string,
            "data": {
                "value"?: number,
                collection_id?: string,
                item_id?: string
            }
        },
        "expires": string | Date
    }
}

// export interface Collectibles {
//     paddles: {attributes: {url: string}, features: {points: 20, time: 0}}[],
//     fields: {attributes: {url: string}, features: {points: 10, time: 0}}[],
//     pickleballs: {attributes: {url: string}, features: {points: 50, time: 60000}}[],
// }
