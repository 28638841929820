import React from 'react';
import ReactDOM, {createRoot} from 'react-dom/client';
import './index.scss';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import store from './store';

const rootElement =  document.getElementById("root");
const root = createRoot(rootElement!);
root.render(
 <Provider store={store}>
  {/*<React.StrictMode>*/}
    <BrowserRouter>
      <App />
    </BrowserRouter>
  {/*</React.StrictMode>*/}
  </Provider>
);
