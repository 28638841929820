import { createSlice } from '@reduxjs/toolkit'

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        isOpened: false
    },
    reducers: {
        openApp: state => {
            state.isOpened  = true;
        },

    }
})


export const {
    openApp
} = settingsSlice.actions

export default settingsSlice.reducer
