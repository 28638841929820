import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import {ReactComponent as SplashIcon} from '../../assets/splash-icon.svg';

const SplashScreen = ({style}) => {
    return (
        <TelegramScreen className='splash-wrapper'>
            <div className='main-container' style={{'justifyContent': 'center', ...style}}>
              <SplashIcon></SplashIcon>
            </div>
        </TelegramScreen>)
}

export default SplashScreen
