// import { useEffect } from "react";
import bs58 from 'bs58';
import nacl from 'tweetnacl';
import {useLocation} from "react-router-dom";

import {useEffect, useState} from "react";
const ConnectWallet = () => {
    // const [count, setCount] = useState('');
    // const [ wallet, setWalletAvail ] = useState(false);
    const location = useLocation();
    const [ provider, setProvider ] = useState(null);
    const [ connected, setConnected ] = useState(false);
    const [ pubKey, setPubKey ] = useState(null);

    // User agent
    // const ua = navigator.userAgent.toLowerCase();
    // const isAndroid = ua.indexOf('android') > -1;
    // const isIphone = ua.indexOf('iphone') > -1;
    //
    // const { id } = useParams();
    useEffect( ()=>{
        const solWindow = window;
        console.log(solWindow?.solana?.isPhantom);
        if (solWindow?.solana?.isPhantom) {
            setProvider(solWindow.solana);
            // setWalletAvail(true);
        } else {
            // const connection = new Connection("https://api.devnet.solana.com", "confirmed");
        }
    }, []);
    // const location = useLocation();
    useEffect( () => {
        provider?.on("connect", (publicKey)=>{
            console.log(`connect event: ${publicKey}`);
            setConnected(true);
            setPubKey(publicKey);
        });
        provider?.on("disconnect", ()=>{
            console.log("disconnect event");
            setConnected(false);
            setPubKey(null);
        });

    }, [provider]);
    // const disconnectHandler = () => {
    //     console.log("disconnect handler");
    //     provider?.disconnect()
    //         .catch((err) => {console.error("disconnect ERROR:", err); });
    // }
    // useEffect(() => {
    //     // execute on location change
    //     console.log('Location changed!', location.pathname);
    //     console.log('Location changed!', location.search);
    // }, [location]);
    const connectHandler = () => {
        console.log(provider);
        if (provider) {
            try {
                provider?.connect();

            } catch (err) {
                console.log('err');
            }
        } else {
            const kp = nacl.box.keyPair();
            const keypairEncoded = {
                publicKey: bs58.encode(kp.publicKey),
                secretKey: bs58.encode(kp.secretKey),
            }
            const dappEncryptionPublicKey = keypairEncoded;
            const params = new URLSearchParams({
                app_url: 'https://t.me/PurrCoingameBot/game',
                dapp_encryption_public_key: dappEncryptionPublicKey.publicKey,
                redirect_link: `https://t.me/PurrCoingameBot?start=redirect/phantom/connect`,
                cluster: 'devnet',
            });

            // const sessionData = {
            //     keypair: dappEncryptionPublicKey,
            //     walletAddress: walletAddress || '',
            //     phantomEncryptionPublicKey: phantomEncryptionPublicKey || '',
            //     session: this.session || '',
            //     nonce: this.nonce || '',
            // }
            // localStorage.setItem('PHANTOM_SESSION_DATA', JSON.stringify(sessionData));
            // Phantom will redirect us to another tab, so the app's state will be lost.
            // We save the 'sessionKeypair' on localstorage to get it again later of the redirection.

            window.open(`https://phantom.app/ul/v1/connect?${params.toString()}`);
        }
    }

    return (
        <div className={'button__connect picklezone-cnct-btn'}>
                    <button className={"white-text-roboto-20"} onClick={connectHandler}>Connect wallet{location.search}</button>
                    {/*<button className={`${connected ? 'show-button' : 'hide-button'}`}>Disconnect</button>*/}
                    { connected ? <div className={'text-wrap'}>{pubKey?.toBase58()}</div> : null }

        </div>
    );
}

export default ConnectWallet;
